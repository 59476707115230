require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("@popperjs/core");
require("jquery");
require("bootstrap");
require("jquery-ui");
var jQuery = require('jquery');


global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

